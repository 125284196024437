export default {
  title: 'Benutzer',
  description: 'Verwalte alle Benutze des Systems.',
  search: 'Benutzer suchen',
  invite: {
    title: 'Benutzer einladen',
    description: 'Lade Benutzer ein, um sie diesem System hinzuzufügen.',
    action: 'Benutzer einladen',
    form: {
      name: 'Vollständiger Name',
      email: 'E-Mail',
    },
  },
  pendingInvites: {
    title: 'Ausstehende Einladungen',
  },
  dropdown: {
    edit: 'Benutzer bearbeiten',
    revokeInvite: 'Einladung widerrufen',
    impersonate: 'Benutzer übernehmen',
  },
};
