import adminNavigation from '~/lib/locale/de/admin-navigation.ts';
import de from '~/lib/locale/de/de.ts';
import organization from '~/lib/locale/de/organization.ts';
import role from '~/lib/locale/de/role.ts';
import user from '~/lib/locale/de/user.ts';

export const supportedLngs = ['de', 'en'];

export const fallbackLng = 'de';

export const defaultNS = 'common';

export const resources = {
  de: {
    common: de,
    adminNavigation: adminNavigation,
    organization: organization,
    role: role,
    user: user,
  },
  en: {
    common: {},
  },
};
